
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as dashboardHXfeDQdKytMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/admin/dashboard.vue?macro=true";
import { default as feedback0X5szevN6NMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/admin/feedback.vue?macro=true";
import { default as loginjOoE70A5qeMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/admin/login.vue?macro=true";
import { default as choose_45categoryejo6rQLXqyMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/choose-category.vue?macro=true";
import { default as defaultLaE3yXUEKiMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/default.vue?macro=true";
import { default as question_451NVkJQIebAdMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/feedback/question-1.vue?macro=true";
import { default as question_452LVIBtG7GL0Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/feedback/question-2.vue?macro=true";
import { default as question_4534uM1Ccal2EMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/feedback/question-3.vue?macro=true";
import { default as thanks29DKOrXnyMMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/feedback/thanks.vue?macro=true";
import { default as indexYeVWh0jnPkMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/index.vue?macro=true";
import { default as check_45prizeqfGItsqDufMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/check-prize.vue?macro=true";
import { default as claim_45prizevfajtTZSIUMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/claim-prize.vue?macro=true";
import { default as index771DYkr2SzMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/index.vue?macro=true";
import { default as max_45playedZpyw4bpWD1Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/max-played.vue?macro=true";
import { default as no_45winRzF1S0x8vLMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/no-win.vue?macro=true";
import { default as prize_45claimedBRmSGwbh2lMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/prize-claimed.vue?macro=true";
import { default as winWtCcvbF4oPMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/win.vue?macro=true";
import { default as wrong_45answernIzKrE630IMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/wrong-answer.vue?macro=true";
import { default as answer_45correctMhATx30yUmMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/answer-correct.vue?macro=true";
import { default as beCFzXDczBQzMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/be.vue?macro=true";
import { default as congratulations5AwsayAh5jMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/congratulations.vue?macro=true";
import { default as formmXvdEizb7wMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/form.vue?macro=true";
import { default as loginUn8EYe9QJcMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/login.vue?macro=true";
import { default as questionYHjDLhidBWMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/question.vue?macro=true";
import { default as thanks0Aw3O5OZDeMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/thanks.vue?macro=true";
import { default as wheelRepT3d1geeMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/wheel.vue?macro=true";
import { default as choose_45categoryjN3onVU1y5Meta } from "~/pages/choose-category.vue?macro=true";
import { default as wrong_45answer9GjULBXgXmMeta } from "~/pages/quiz/wrong-answer.vue?macro=true";
export default [
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardHXfeDQdKytMeta || {},
    alias: ["/dashboard"],
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/admin/dashboard.vue")
  },
  {
    name: "admin-feedback",
    path: "/admin/feedback",
    meta: feedback0X5szevN6NMeta || {},
    alias: ["/dashboard"],
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/admin/feedback.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: loginjOoE70A5qeMeta || {},
    alias: ["/login"],
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/admin/login.vue")
  },
  {
    name: "choose-category",
    path: "/choose-category",
    meta: choose_45categoryejo6rQLXqyMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/choose-category.vue")
  },
  {
    name: "default",
    path: "/default",
    meta: defaultLaE3yXUEKiMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/default.vue")
  },
  {
    name: "feedback-question-1",
    path: "/feedback/question-1",
    meta: question_451NVkJQIebAdMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/feedback/question-1.vue")
  },
  {
    name: "feedback-question-2",
    path: "/feedback/question-2",
    meta: question_452LVIBtG7GL0Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/feedback/question-2.vue")
  },
  {
    name: "feedback-question-3",
    path: "/feedback/question-3",
    meta: question_4534uM1Ccal2EMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/feedback/question-3.vue")
  },
  {
    name: "feedback-thanks",
    path: "/feedback/thanks",
    meta: thanks29DKOrXnyMMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/feedback/thanks.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexYeVWh0jnPkMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/index.vue")
  },
  {
    name: "quiz-check-prize",
    path: "/quiz/check-prize",
    meta: check_45prizeqfGItsqDufMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/check-prize.vue")
  },
  {
    name: "quiz-claim-prize",
    path: "/quiz/claim-prize",
    meta: claim_45prizevfajtTZSIUMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/claim-prize.vue")
  },
  {
    name: "quiz",
    path: "/quiz",
    meta: index771DYkr2SzMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/index.vue")
  },
  {
    name: "quiz-max-played",
    path: "/quiz/max-played",
    meta: max_45playedZpyw4bpWD1Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/max-played.vue")
  },
  {
    name: "quiz-no-win",
    path: "/quiz/no-win",
    meta: no_45winRzF1S0x8vLMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/no-win.vue")
  },
  {
    name: "quiz-prize-claimed",
    path: "/quiz/prize-claimed",
    meta: prize_45claimedBRmSGwbh2lMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/prize-claimed.vue")
  },
  {
    name: "quiz-win",
    path: "/quiz/win",
    meta: winWtCcvbF4oPMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/win.vue")
  },
  {
    name: "quiz-wrong-answer",
    path: "/quiz/wrong-answer",
    meta: wrong_45answernIzKrE630IMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/quiz/wrong-answer.vue")
  },
  {
    name: "static-answer-correct",
    path: "/static/answer-correct",
    meta: answer_45correctMhATx30yUmMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/answer-correct.vue")
  },
  {
    name: "static-be",
    path: "/static/be",
    meta: beCFzXDczBQzMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/be.vue")
  },
  {
    name: "static-congratulations",
    path: "/static/congratulations",
    meta: congratulations5AwsayAh5jMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/congratulations.vue")
  },
  {
    name: "static-form",
    path: "/static/form",
    meta: formmXvdEizb7wMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/form.vue")
  },
  {
    name: "static-login",
    path: "/static/login",
    meta: loginUn8EYe9QJcMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/login.vue")
  },
  {
    name: "static-question",
    path: "/static/question",
    meta: questionYHjDLhidBWMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/question.vue")
  },
  {
    name: "static-thanks",
    path: "/static/thanks",
    meta: thanks0Aw3O5OZDeMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/thanks.vue")
  },
  {
    name: "static-wheel",
    path: "/static/wheel",
    meta: wheelRepT3d1geeMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250227195147/pages/static/wheel.vue")
  },
  {
    name: "choose-category-nl",
    path: "/kies-categorie",
    meta: choose_45categoryjN3onVU1y5Meta || {},
    component: () => import("~/pages/choose-category.vue")
  },
  {
    name: "wrong-answer-nl",
    path: "/quiz/fout-antwoord",
    meta: wrong_45answer9GjULBXgXmMeta || {},
    component: () => import("~/pages/quiz/wrong-answer.vue")
  }
]